import React from 'react';
import ReactDOM from 'react-dom/client';
import ThemedApp from './ThemedApp';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import { persistStore } from 'redux-persist';
import { ConfigProvider } from 'antd';
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    theme={{
      components: {
        Tag: {
          defaultColor: '#722ED1',
        },
      },
    }}
  >
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemedApp />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ConfigProvider>
);
