import { Avatar, Card, Col, ConfigProvider, Row, theme } from 'antd';
import { Text } from '../components/Text';
import React from 'react';
const { useToken } = theme;

type CurrentPriceProp = {
  color: string;
  icon: React.ReactNode;
  title: string;
  value: number;
  percentage?: number;
  cost_to_move_up_usd?: number;
  cost_to_move_down_usd?: number;
};

const CurrentPriceCard = (props: CurrentPriceProp) => {
  const {
    color,
    icon,
    title,
    value,
    percentage,
    cost_to_move_down_usd,
    cost_to_move_up_usd,
  } = props;
  const { token } = useToken();
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            paddingLG: 12,
          },
        },
      }}
    >
      <Card>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            gap: token.paddingMD,
          }}
        >
          <Col>
            <Avatar
              shape="square"
              size={32}
              style={{ backgroundColor: color }}
              icon={icon}
            />
          </Col>
          <Col style={{ flexGrow: 1 }}>
            <Row>
              <Col span={24}>
                <Text size="lg" style={{ color: 'black', fontWeight: '600' }}>
                  {title}
                </Text>
              </Col>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Text size="md" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  {`$${value}`}
                </Text>
                {percentage && (
                  <Text
                    size="md"
                    style={{
                      color: percentage > 0 ? token.green6 : token.red6,
                    }}
                  >
                    {percentage > 0 ? `+${percentage}%` : `${percentage}%`}
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'column', paddingLeft: '50px' }}>
          <p style={{ padding: '0', margin: '0' }}>
            Cost to move up USD: {cost_to_move_up_usd}
          </p>
          <p style={{ padding: '0', margin: '0' }}>
            Cost to move down USD: {cost_to_move_down_usd}
          </p>
        </Row>
      </Card>
    </ConfigProvider>
  );
};

export default CurrentPriceCard;
