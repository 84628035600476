import axios from '../axios';
import { ShidoPriceGraph } from '../../types/DataTypes';

export const GetShidoPriceGraph = async () => {
  try {
    const endPoint = `token/price/`;
    const res = await axios.get<Array<ShidoPriceGraph>>(endPoint);
    if (!res?.data?.length) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error update tags', err);
    return Promise.reject(err);
  }
};

export const GetAggregatedGraph = async () => {
  try {
    const endPoint = `token/aggregated-volume/`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data?.length) throw 'Something went wrong';
    console.log('aggregated graph data >>', res);
    return res.data;
  } catch (err) {
    console.log('error Aggregated api', err);
    return Promise.reject(err);
  }
};
