import { Avatar, Card, Col, ConfigProvider, Row, theme } from 'antd';
import React from 'react';
import { LineChartOutlined } from '@ant-design/icons';
import { Text } from '../components/Text';
import { TinyArea, TinyLine } from '@ant-design/plots';
const { useToken } = theme;

type weekOnweekProp = {
  color: string;
  icon: React.ReactNode;
  title: string;
  value?: number | string;
  percentage: number;
};
const WeekOnWeekCard = (props: weekOnweekProp) => {
  const { color, icon, title, value, percentage } = props;
  const { token } = useToken();
  const data = [264, 33, 430, 525, 592, 492, 467, 513, 546, 983, 340, 192];
  const config = {
    //height: 40,

    autoFit: true,
    data,
    smooth: true,
    areaStyle: {
      fill: 'rgba(114, 46, 209, 0.1)',
    },
    line: {
      style: {
        stroke: token.purple5,
      },
    },
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            paddingLG: 12,
          },
        },
      }}
    >
      <Card>
        <Row
          className="week-on-week-gap"
          style={{
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            // gap: token.paddingMD,
          }}
        >
          <Col>
            <Avatar
              shape="square"
              size={32}
              style={{ backgroundColor: color }}
              icon={icon}
            />
          </Col>
          <Col style={{ flexGrow: 1 }}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Text size="lg" style={{ color: 'black', fontWeight: '600' }}>
                {title}
              </Text>
            </Row>
            <Row>
              {' '}
              <Col
                style={{
                  maxWidth: '98px',
                  maxHeight: '48px',
                  position: 'relative',
                }}
              >
                {value ? (
                  <>
                    {' '}
                    <Text size="md" style={{ color: color, fontWeight: '400' }}>
                      {value}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      size="sm"
                      style={{
                        color: color,
                        fontWeight: '400',
                        opacity: '0.3',
                      }}
                    >
                      Coming Soon
                    </Text>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  );
};

export default WeekOnWeekCard;
