import axios from '../axios';
import { UniswapDataType, MexcDataType } from '../../types/DataTypes';

export const GetCurrentPriceUniswap = async () => {
  try {
    const endPoint = `/token/uniswap/`;
    const res = await axios.get<UniswapDataType>(endPoint);
    if (!res?.data) throw 'Something went wrong';

    return res.data;
  } catch (err) {
    console.log('error update tags', err);
    return Promise.reject(err);
  }
};

export const GetCurrentPriceMexc = async () => {
  try {
    const endPoint = `token/mexc/`;
    const res = await axios.get<MexcDataType>(endPoint);
    if (!res?.data) throw 'Something went wrong';

    return res.data;
  } catch (err) {
    console.log('error update tags', err);
    return Promise.reject(err);
  }
};
