import Icon from '@ant-design/icons';

export const Covey = () => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 15"
        fill="none"
      >
        <path
          d="M10.1852 6.72753V2.88322L7.67786 0.974487L2.9396 2.63355L2.73289 3.16779C2.62014 3.4604 2.02416 4.99061 1.44698 6.49934C1.07114 7.47653 0.775839 8.26042 0.563759 8.82955C0.445638 9.14633 0.295302 9.56781 0.273826 9.62419L0 10.3061L0.0456371 10.4322C0.0536908 10.451 0.244295 10.9638 2.6443 16.5826L2.82685 17.0121L7.18659 19.0256L10.1852 16.7356V12.8537C8.91008 12.5128 7.96243 11.4255 7.96243 9.79332C7.96243 8.16109 8.90202 7.07384 10.1852 6.73022V6.72753ZM6.87518 16.2041L4.69262 15.1947C3.94094 13.439 2.99061 11.1974 2.6094 10.298C2.94765 9.35036 3.92752 6.80002 4.79732 4.55571L7.24833 3.69933L7.76377 4.09128V5.29665C7.34766 5.59464 6.96108 5.98122 6.62551 6.47787C5.95974 7.35841 5.55974 8.48324 5.55974 9.79332C5.55974 11.1034 5.97585 12.2765 6.66578 13.1625C7.00135 13.643 7.38256 14.0188 7.7933 14.3088V15.5061L6.88323 16.2068L6.87518 16.2041Z"
          fill="white"
        />
        <path
          d="M21.7422 9.62419C21.7261 9.57855 21.5731 9.1517 21.4523 8.82955C21.2402 8.26042 20.9422 7.47653 20.5691 6.49934C19.9382 4.85102 19.2832 3.17047 19.2778 3.15436L19.0738 2.63355L14.3355 0.974487L11.8308 2.88322V6.69532C12.8885 6.92619 13.7449 7.65371 14.0671 8.77318L16.2657 7.78525C16.0778 7.28055 15.8228 6.82418 15.5087 6.42149C15.1409 5.89263 14.7114 5.48726 14.2496 5.18659V4.09128L14.765 3.69933L17.2134 4.55571C18.0858 6.80002 19.063 9.35036 19.4013 10.2953C19.0254 11.1866 18.0697 13.4282 17.3154 15.1947L15.1328 16.2014L14.2174 15.5007V14.4054C14.7087 14.0913 15.1597 13.6645 15.5436 13.098C15.7476 12.8269 15.9275 12.5343 16.0805 12.2202L13.7932 11.4578C13.3744 12.2068 12.6684 12.7034 11.8281 12.8859V16.7303L14.8241 19.0175L19.1838 17.0041L19.3664 16.5746C21.5167 11.5356 21.8093 10.8242 21.8416 10.7383L21.9999 10.3007L21.7369 9.61614L21.7422 9.62419Z"
          fill="white"
        />
      </svg>
    )}
  />
);
