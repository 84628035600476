import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Text } from '../components/Text';
import { Pie, PieConfig, measureTextWidth } from '@ant-design/plots';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { CirculatingSupplyApi } from '../api';

type TokenInfo = {
  contract_address: string;
  circulating_supply: string;
};

type SupplyData = {
  id: number;
  title: string;
  owner: number;
  percentage: number;
  value: string;
};
type chartData = {
  id: number;
  type: string;
  owner: number;
  percentage: number;
  value: number;
};

type CirculatingSupply = {
  tokeninfo: TokenInfo;
  supply_data: SupplyData[];
};

//const PlotColors = ['#D3ADF7', '#B37FEB', '#9254DE', '#722ED1', '#531DAB'];
const PlotColors = [
  '#D3ADF7',
  '#B37FEB',
  '#9254DE',
  '#722ED1',
  '#531DAB',
  '#391B89',
];
const CirculatingSupply = () => {
  const { sm } = useBreakpoint();
  const [cirSupply, setCirculatingSupply] = useState<CirculatingSupply>();
  const [chartData, setChartData] = useState<chartData[]>([]);

  const fetchCirculatingSupply = async () => {
    try {
      const res = await CirculatingSupplyApi();
      if (res) {
        setCirculatingSupply(res);
        const aaa = res.supply_data.map((item) => ({
          ...item,
          type: item.title,
          value: parseInt(item.value),
        }));

        setChartData(aaa);
      }
    } catch (err) {
      console.log('fetcch error Circulating Supply', err);
    }
  };

  useEffect(() => {
    fetchCirculatingSupply();
  }, []);

  function renderStatistic(containerWidth: any, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`;
  }

  const config: PieConfig = {
    color: PlotColors,
    height: 200,
    width: 200,
    appendPadding: 10,
    data: chartData && chartData.length ? chartData : [],
    angleField: 'value',
    colorField: 'title',
    radius: 1,
    innerRadius: 0.61,
    legend: false,

    // legend: {
    //   items: data.map((item) => ({
    //     name: item.type,
    //     value: item.value,
    //     marker: {
    //       style: {
    //         fill: item.color,
    //       },
    //     },
    //   })),

    //   itemName: {
    //     style: {
    //       fill: 'rgba(0, 0, 0, 0.45)',
    //       fontSize: 14,
    //     },
    //   },
    //   itemValue: {
    //     style: {
    //       fill: 'black',
    //       fontSize: 14,
    //     },
    //   },
    // },
    meta: {
      value: {
        formatter: (v: any) => `${v} ¥`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '',
    },
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontSize: '15px',
          fontWeight: '600 ',
        },
        customHtml: (container: any, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Total';
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '12px',
          color: 'rgba(0, 0, 0, 0.45)',
          fontWeight: 400,
        },
        customHtml: (container: any, view: any, datum: any, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? ` ${datum.value}`
            : ` ${data.reduce((r: any, d: any) => r + d.value, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '14px',
        width: '100%',
      }}
    >
      <Col span={24}>
        <Text
          size="xxl"
          style={{ color: 'black', fontWeight: '600', marginBottom: '8px' }}
        >
          Circulating Supply
        </Text>
      </Col>
      <Col span={24}>
        <Row
          //className="circulating-supply-flex"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // gap: '14px',
          }}
        >
          <Col lg={8} md={24} sm={8} xs={24}>
            <Pie {...config} />
          </Col>
          <Col lg={16} md={24} sm={16} xs={24}>
            <Row>
              <Text
                size={'sm'}
                style={{
                  color: 'rgba(0, 0, 0, 0.45)',
                  fontWeight: '400',
                  marginBottom: '14px',
                  width: '100%',
                  textAlign: 'end',
                }}
              >
                Next week’s growth
              </Text>
              {chartData.map((item, index) => (
                <StatsComponent
                  // key={item.id}
                  key={index}
                  title={item.type}
                  value={item.value}
                  percentage={item.percentage}
                  growth={0}
                  color={PlotColors[chartData.indexOf(item)]}
                />
              ))}
              {/* <StatsComponent
                title="Treasury"
                value={4400000}
                percentage={26}
                growth={0}
                color="purple"
              />{' '}
              <StatsComponent
                title="Seed"
                value={4000000}
                percentage={16}
                growth={34340}
                color="purple"
              />{' '}
              <StatsComponent
                title="Public"
                value={2500000}
                percentage={12}
                growth={503434}
                color="purple"
              />{' '}
              <StatsComponent
                title="Advisors"
                value={750000}
                percentage={3}
                growth={0}
                color="purple"
              /> */}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CirculatingSupply;

interface stateProp {
  color: string;
  title: string;
  value: number;
  percentage: number;
  growth: number;
}

const StatsComponent = (props: stateProp) => {
  const { color, title, value, percentage, growth } = props;
  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '12px',
        gap: '6px',
      }}
    >
      <Col span={12}>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: color,
                marginRight: '4px',
              }}
            ></div>
            <Text size={'md'} style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {title}
            </Text>
          </Col>
          <Col>
            <Text
              size={'md'}
              style={{ color: 'rgba(0, 0, 0, 0.88', fontWeight: '600' }}
            >
              {value}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={11}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Col>
            <Text size={'md'} style={{ color: '#9254DE' }}>
              {percentage}%
            </Text>
          </Col>
          <Col>
            {/* <Text
              size={'md'}
              style={{ color: growth > 0 ? '#52C41A' : 'black' }}
            >
              +{growth}
            </Text> */}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
