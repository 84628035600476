import { Card, Col, Layout, Row, theme } from 'antd';
import { Text } from '../components/Text';
import WeekOnWeekCard from '../components/WeekOnWeekCard';
import {
  LineChartOutlined,
  WalletOutlined,
  UserOutlined,
  StarOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import CurrentPriceCard from '../components/CurrentPriceCard';
import { Covey } from '../components/Icons';
import { useEffect, useState } from 'react';
import {
  GetCurrentPriceUniswap,
  GetCurrentPriceMexc,
  GetStatistic,
} from '../api';
import ShidoPriceGraph from '../components/ShidoPriceGraph';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import CirculatingSupply from '../components/CirculatingSupply';
import TotalVolume from '../components/TotalVolume';
import FearGreed from '../components/FearGreed';
import {
  UniswapDataType,
  MexcDataType,
  StatisticWalletData,
} from '../types/DataTypes';
import ShidoAggregatedGraph from '../components/ShidoAggregatedGraph';

const { useToken } = theme;
const { Content } = Layout;

const Dashboard = () => {
  const { token } = useToken();
  const { md } = useBreakpoint();
  const [currentPriceUniswap, setCurrentPriceUniswap] =
    useState<UniswapDataType>();
  const [currentPriceMexc, setCurrentPriceMexc] = useState<MexcDataType>();
  const [statistic, setStatistic] = useState<StatisticWalletData>();

  const getCurrentPriceUniswap = async () => {
    try {
      const res = await GetCurrentPriceUniswap();
      setCurrentPriceUniswap(res);
    } catch (error) {
      console.error('Error fetching current price:', error);
    }
  };

  const getCurrentPriceMexc = async () => {
    try {
      const res = await GetCurrentPriceMexc();
      setCurrentPriceMexc(res);
    } catch (error) {
      console.error('Error fetching current price:', error);
    }
  };

  const getStatistic = async () => {
    try {
      const res = await GetStatistic();
      setStatistic(res);
    } catch (error) {
      console.error('Error fetching statistic :', error);
    }
  };

  useEffect(() => {
    getCurrentPriceUniswap();
    getCurrentPriceMexc();
    getStatistic();
  }, []);
  return (
    <Content>
      <Row
        gutter={[12, 12]}
        style={{
          padding: '16px',
          //gap: '12px',
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={9}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
          }}
        >
          <Col
            style={{
              backgroundColor: token.colorBgContainer,
              padding: token.padding,
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              //flexGrow: 1,
            }}
          >
            <Text size="xxl" style={{ color: 'black', fontWeight: '600' }}>
              Current price
            </Text>

            {currentPriceUniswap && (
              <CurrentPriceCard
                color={token.purple6}
                title={'UniSwap: ' + currentPriceUniswap.symbol}
                value={currentPriceUniswap.price}
                percentage={Number(
                  parseFloat(
                    currentPriceUniswap.price_change_percentage
                  ).toFixed(2)
                )}
                icon={<Covey />}
                cost_to_move_up_usd={currentPriceUniswap.cost_to_move_up_usd}
                cost_to_move_down_usd={
                  currentPriceUniswap.cost_to_move_down_usd
                }
              />
            )}
            {currentPriceMexc && (
              <CurrentPriceCard
                color={token.purple6}
                title={'MEXC: ' + currentPriceMexc?.symbol}
                value={parseFloat(
                  currentPriceMexc?.current_price.toString() || '0'
                )}
                percentage={Number(
                  parseFloat(currentPriceMexc.price_change_percent).toFixed(2)
                )}
                icon={<Covey />}
                cost_to_move_up_usd={currentPriceMexc.cost_to_move_up_usd}
                cost_to_move_down_usd={currentPriceMexc.cost_to_move_down_usd}
              />
            )}
          </Col>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: token.colorBgContainer,
              padding: token.padding,
              borderRadius: '12px',
              gap: '12px',
              flexGrow: '1',
            }}
          >
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text size="xxl" style={{ color: 'black', fontWeight: '600' }}>
                Statistics
              </Text>
              {/* <Text size="sm" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                Trend last 4 wk
              </Text> */}
            </Row>
            <WeekOnWeekCard
              color={token.purple6}
              icon={<LineChartOutlined />}
              title="Token Holders"
              value={statistic ? statistic.wallet_numbers : 0}
              percentage={14}
            />

            <WeekOnWeekCard
              color={token.green}
              icon={<Covey />}
              title="MarketCap:SHIDO"
              value={statistic ? statistic.market_cap : 0}
              percentage={4}
            />
          </Col>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={9}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Row
            style={{
              backgroundColor: token.colorBgContainer,
              padding: token.paddingLG,
              flexDirection: 'column',
              borderRadius: '12px',
            }}
          >
            <Text
              size="xxl"
              style={{ color: 'black', fontWeight: '600', marginBottom: '8px' }}
            >
              Daily Shido Price
            </Text>
            <Card style={{ maxHeight: '256px', maxWidth: '100%' }}>
              <ShidoPriceGraph />
            </Card>
          </Row>
          <Row
            style={{
              backgroundColor: token.colorBgContainer,
              padding: token.paddingLG,
              borderRadius: '12px',
            }}
          >
            <CirculatingSupply />
          </Row>
        </Col>
        <Col xxl={6} xl={24} md={24} sm={24} xs={24}>
          <Row gutter={[12, 12]}>
            <Col xxl={24} xl={12} md={24} sm={24} xs={24}>
              <div
                style={{
                  backgroundColor: token.colorBgContainer,
                  padding: token.paddingLG,
                  borderRadius: '12px',
                }}
              >
                <TotalVolume />
              </div>
            </Col>
            <Col xxl={24} xl={12} md={24} sm={24} xs={24}>
              <div
                style={{
                  backgroundColor: token.colorBgContainer,
                  padding: token.paddingLG,
                  borderRadius: '12px',
                }}
              >
                <FearGreed />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: token.colorBgContainer,
          padding: token.paddingLG,
          flexDirection: 'column',
          borderRadius: '12px',
          margin: '16px',
          maxWidth: '100%',
        }}
      >
        <Text
          size="xxl"
          style={{ color: 'black', fontWeight: '600', marginBottom: '8px' }}
        >
          Daily Shido Aggregated Volume
        </Text>
        <ShidoAggregatedGraph />
      </Row>
    </Content>
  );
};

export default Dashboard;
