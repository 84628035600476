import { Menu, Layout, Row, Col, Button, theme, MenuProps, Avatar } from 'antd';
import React from 'react';
import {
  AppstoreOutlined,
  MailOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useDispatch, useSelector } from 'react-redux';
import img from '../../src/assets/svg/Logo Placeholder.svg';
import { logout, closeSidebar, openSidebar } from '../redux/reducers';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

const { Sider } = Layout;
const { useToken } = theme;

export default function Sidebar() {
  const mobileSider = useSelector((state: any) => state.app.MobileSider);
  const { token } = useToken();
  const { md } = useBreakpoint();
  const dispatch = useDispatch();
  const onClick = (e: any) => {
    console.log('click ', e);
  };

  type MenuItem = Required<MenuProps>['items'][number];

  const getItem = (
    label: string,
    key: string,
    to?: string | null,
    icon?: React.ReactElement | null,
    children?: any,
    type?: string
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      type,
      label: to ? <Link to={to}>{label}</Link> : label,
      onClick: () => {
        dispatch(closeSidebar());
      },
    } as MenuItem;
  };

  const items = [
    getItem('Dashboard', '1', '/', <AppstoreOutlined />),
    getItem('Token Holder', '2', '/Token_Holder', <UserOutlined />),
  ];

  return (
    <Sider
      className={mobileSider ? 'sider-for-mobile' : ''}
      width={md ? 272 : mobileSider ? 272 : 0}
      reverseArrow={true}
      style={{
        zIndex: 99,
        overflow: 'auto',
        height: '100vh',
        alignItems: 'center',
        backgroundColor: token.colorBgContainer,
      }}
      trigger={null}
    >
      <Row
        style={{
          display: mobileSider ? '' : 'none',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '9px',
          padding: token.paddingMD,
          backgroundColor: token.colorBgContainer,
        }}
      >
        <Avatar
          shape="square"
          size={32}
          style={{ backgroundColor: token['purple-1'], color: token.purple6 }}
          icon={<MenuOutlined />}
          onClick={() => {
            if (mobileSider) {
              dispatch(closeSidebar());
            } else {
              dispatch(openSidebar());
            }
            console.log('tauseefCheck', mobileSider);
          }}
        />
      </Row>
      <Row
        style={{
          display: mobileSider ? 'none' : '',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '9px',
          padding: token.paddingMD,
          backgroundColor: token.colorBgContainer,
        }}
      >
        <Col>
          <img src={img} />
        </Col>
      </Row>
      <Menu
        onClick={onClick}
        mode="inline"
        items={items}
        className={'scroll-container'}
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
        }}
      />

      <Row
        align={'middle'}
        justify={'space-around'}
        style={{ position: 'absolute', bottom: '0.1em', width: '100%' }}
      >
        <Button
          style={{ width: '100%', margin: token.marginLG }}
          type="primary"
          ghost={true}
          icon={<LogoutOutlined />}
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </Button>
      </Row>
    </Sider>
  );
}
