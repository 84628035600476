import axios from '../axios';

type walletAgetype = {
  id: number;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  address: string;
  age: number;
  first_txn_time: string;
  Balance: string;
  label: string[];
};

export const WalletAgeApi = async (id: string) => {
  try {
    const endPoint = `token-holders/wallet/${id}`;
    const res = await axios.get<walletAgetype>(endPoint);
    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error Wallet Address API', err);
    return Promise.reject(err);
  }
};
