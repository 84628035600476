import axios from '../axios';

type FearAndGreedIndex = {
  name: string;
  data: FearAndGreedData[];
  metadata: {
    error: null | string;
  };
};

type FearAndGreedData = {
  value: string;
  value_classification: string;
  timestamp: string;
  time_until_update: string;
};

export const FearGreedApi = async (): Promise<FearAndGreedData> => {
  try {
    const endPoint = `token-holders/fear-n-greed/`;
    const res = await axios.get<FearAndGreedIndex>(endPoint);
    if (!res?.data) throw 'Something went wrong';
    return res.data.data[0];
  } catch (err) {
    console.log('Fear & Greed Address API', err);
    return Promise.reject(err);
  }
};
