import axios from '../axios';
type TokenInfo = {
  contract_address: string;
  circulating_supply: string;
};

type SupplyData = {
  id: number;
  title: string;
  owner: number;
  percentage: number;
  value: string;
};

type CirculatingSupply = {
  tokeninfo: TokenInfo;
  supply_data: SupplyData[];
};

export const CirculatingSupplyApi = async () => {
  try {
    const endPoint = `/token/coin-circulating-supply/`;
    const res = await axios.get<CirculatingSupply>(endPoint);
    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error CirculatingSupply', err);
    return Promise.reject(err);
  }
};
