import { Layout, Row, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import DataTable from '../components/DataTable';
import { Text } from '../components/Text';
import { GetTokenHoldr } from '../api/apiCalls/TokenHolders';
const { useToken } = theme;
const { Content } = Layout;

interface TokenData {
  id: number;
  labels: string[];
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  contract_decimals: string;
  contract_name: string;
  contract_ticker_symbol: string;
  contract_address: string;
  supports_erc: null | string;
  logo_url: string;
  address: string;
  balance: string;
  total_supply: string;
  block_height: string;
}

const TokenHolders = () => {
  const { token } = useToken();
  const [tokenData, setTokenData] = useState<TokenData[]>([]);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const data = await GetTokenHoldr();
        setTokenData(data);
      } catch (error) {
        console.error('Error fetching token data:', error);
      }
    };
    fetchTokenData();
  }, []);
  return (
    <Content
      style={{
        padding: '16px',
        margin: '16px',
        backgroundColor: token.colorBgContainer,
        minWidth: '758px',
        // overflow: 'scroll',
      }}
    >
      <Row style={{ padding: '6px' }}>
        <Text size="xxl" style={{ color: 'black', fontWeight: '600' }}>
          Token Holders
        </Text>
      </Row>
      <DataTable data={tokenData} setTokenData={setTokenData}></DataTable>
    </Content>
  );
};

export default TokenHolders;
