import { store } from '../../redux/store';
import { User } from '../../utils';
import axios from '../axios';

type LoginUser = {
  status: 'success';
  data: {
    user_id: number;
    email: string;
    exp: number;
    access_token: string;
    refresh_token: string;
  };
};

export const Userlogin = async (payload: any): Promise<LoginUser> => {
  try {
    const endPoint = 'user/login/';
    const res = await axios.post(endPoint, payload);
    if (!res?.data) throw 'Something went wrong';

    return res.data;
  } catch (err) {
    console.log('error while login', err);
    return Promise.reject(err);
  }
};

export const getUser = async (): Promise<{ status: boolean; data: User }> => {
  try {
    const endPoint = 'user/current/';
    const res = await axios.get(endPoint);

    return res.data;
  } catch (err) {
    console.log('error get user', err);
    return Promise.reject(err);
  }
};

export const RefreshToken = async (payload: any): Promise<string> => {
  try {
    const endPoint = 'user/refresh/';
    const res = await axios.post(endPoint, payload);
    if (!res?.data) throw 'Something went wrong';

    return res.data.access_token;
  } catch (err) {
    console.log('error while Refresh Token fetch', err);
    return Promise.reject(err);
  }
};
