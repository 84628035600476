import axios from '../axios';
import { VolumeDataType } from '../../types/DataTypes';

export const GetVolumes = async () => {
  try {
    const endPoint = `token/volume/`;
    const res = await axios.get<VolumeDataType>(endPoint);
    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error update tags', err);
    return Promise.reject(err);
  }
};
