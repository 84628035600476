import { Card, Col, ConfigProvider, Row, theme } from 'antd';
import React, { Component, useEffect, useState } from 'react';
import { Text } from '../components/Text';
import { Segmented } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { TinyArea } from '@ant-design/plots';
import { GetVolumes, GetCurrentPriceMexc } from '../api';
import { VolumeDataType, MexcDataType } from '.././types/DataTypes';

const { useToken } = theme;

const TotalVolume = () => {
  const { token } = useToken();
  const [pressureData, setPressureData] = useState<VolumeDataType>();
  const [MEXCVolume, setMEXCVolume] = useState<MexcDataType>();

  const fetchVolumeData = async () => {
    try {
      const res = await GetVolumes();
      setPressureData(res);
    } catch (error) {
      console.log('Error while fetchPressureData ', error);
    }
  };

  const fectchMEXCvolume = async () => {
    try {
      const res = await GetCurrentPriceMexc();
      if (res) {
        setMEXCVolume(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVolumeData();
    fectchMEXCvolume();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            colorBgLayout: token.purple1,
            itemSelectedBg: token.purple5,
            itemSelectedColor: token.colorWhite,
          },
        },
      }}
    >
      <Row style={{ gap: '12px' }}>
        <Text
          size="xxl"
          style={{ color: 'black', fontWeight: '600', width: '100%' }}
        >
          Total Volume
        </Text>

        <TotalVolumeGraph
          title="DEX Volume"
          value={pressureData?.dex_value.toString()}
          color="green"
        />
        {pressureData && (
          <TotalVolumeGraph
            title="Buying Pressure"
            value={pressureData?.buying_pressure.toString()}
            percentage={pressureData?.buying_pressure_change}
            color={pressureData.buying_pressure_change > 0 ? 'green' : 'red'}
          />
        )}
        {pressureData && (
          <TotalVolumeGraph
            title="Selling Pressure "
            value={pressureData?.selling_pressure.toString()}
            percentage={pressureData?.selling_pressure_change}
            color={pressureData.selling_pressure_change > 0 ? 'green' : 'red'}
          />
        )}

        <TotalVolumeGraph
          title="MEXC Volume "
          value={MEXCVolume ? MEXCVolume.current_volume.toFixed(2) : '0'}
          color={1 > 0 ? 'green' : 'red'}
        />
      </Row>
    </ConfigProvider>
  );
};

export default TotalVolume;

interface GraphProps {
  title?: string;
  value?: string | number;
  percentage?: number;
  color?: 'red' | 'green';
}

const TotalVolumeGraph = (props: GraphProps) => {
  const { token } = useToken();
  const { title, value, percentage, color } = props;
  const data = [204, 603, 430, 825, 592, 490, 367, 813, 746, 983, 340, 192];

  const config = {
    //height: 40,

    autoFit: true,
    data,
    smooth: true,
    areaStyle: {
      fill: color === 'green' ? token.green2 : token.red2,
    },
    line: {
      style: {
        stroke: color,
      },
    },
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            paddingLG: 12,
          },
        },
      }}
    >
      <Row style={{ width: '100%' }}>
        <Text
          size="sm"
          style={{ color: 'black', fontWeight: '600', width: '100%' }}
        >
          {title}
        </Text>
        <Card style={{ width: '100%' }}>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              flexWrap: 'nowrap',
            }}
          >
            <Col span={14} style={{ position: 'relative' }}>
              {/* <TinyArea
                {...config}
                style={{ height: '48px', opacity: '0.2' }}
              />
              <Text
                style={{
                  color: 'rgba(0, 0, 0, 0.45)',
                  position: 'absolute',
                  left: '30%',
                  top: '20%',
                }}
              >
                Coming Soon
              </Text> */}
            </Col>
            <Col
              span={10}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Text size="lg" style={{ color: 'black', fontWeight: '600' }}>
                {value}
              </Text>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                {percentage && (
                  <>
                    {percentage > 0 ? (
                      <CaretUpOutlined style={{ color: color }} />
                    ) : (
                      <CaretDownOutlined style={{ color: color }} />
                    )}

                    <Text size="md" style={{ color: color }}>
                      {percentage}%
                    </Text>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </Row>
    </ConfigProvider>
  );
};
