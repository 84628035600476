import React, { useState } from 'react';
import { Select, SelectProps, Table, Tag, theme } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import { UpdateTokenHoldrTag } from '../api/apiCalls';
import { useNavigate } from 'react-router-dom';
const { useToken } = theme;

interface DataType {
  id: number;
  labels: string[];
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  contract_decimals: string;
  contract_name: string;
  contract_ticker_symbol: string;
  contract_address: string;
  supports_erc: null | string;
  logo_url: string;
  address: string;
  balance: string;
  total_supply: string;
  block_height: string;
}

interface prop {
  data: DataType[];
  setTokenData: (data: DataType[]) => void;
}

const DataTable = (prop: prop) => {
  const navigate = useNavigate();
  const { data, setTokenData } = prop;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [rowSelection, setRowSelection] = useState<
    TableRowSelection<DataType> | undefined
  >({});

  const handleChange = (id: number, value: string[]) => {
    UpdateTokenHoldrTag(value, id);

    const dataIndex = data.findIndex((item) => item.id === id);

    if (dataIndex !== -1) {
      //shallow copy data array
      const updatedData = [...data];

      // Update labels specific item
      updatedData[dataIndex] = {
        ...updatedData[dataIndex],
        labels: value,
      };
      setTokenData(updatedData);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Wallet address',
      dataIndex: 'address',
      ellipsis: true,
      filters: [],
      onCell: (record) => ({
        onClick: () => onRowClick(record),
        style: { cursor: 'pointer' },
      }),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance.localeCompare(b.balance),
      render: (balance) => <span>{Number(balance).toLocaleString()}</span>,
      ellipsis: true,
    },
    {
      title: 'Label',
      dataIndex: 'labels',
      ellipsis: true,
      render: (labels, record) => (
        <Select
          mode="tags"
          style={{ width: '100%' }}
          onChange={(value) => handleChange(record.id, value)}
          open={false}
          suffixIcon={false}
          defaultValue={labels}
          //options={[labels]}
          //value={labels}
        />
      ),
    },
  ];

  const onRowClick = (record: DataType) => {
    // Handle the row click here
    console.log('Clicked row data:>>>>', record);
    navigate(`/Single_Token/${record.address}`);
  };

  return (
    <Table
      // rowSelection={rowSelection}
      // pagination={{ pageSize: 5 }}
      columns={columns}
      dataSource={data}
      rowKey="id"
      style={{ width: '100%' }}
      // onRow={(record) => ({
      //   onClick: () => onRowClick(record),
      // })}
    />
  );
};

export default DataTable;
