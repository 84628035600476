import React, { useEffect, useState } from 'react';
import { Line, LineConfig, TinyLine } from '@ant-design/plots';
import Item from 'antd/es/list/Item';
import { format } from 'date-fns';
import { GetShidoPriceGraph } from '../api';
import { ShidoPriceGraph as ShidoPriceGraphtype } from '../types/DataTypes';

const ShidoPriceGraph = () => {
  const [priceAggregated, setPriceAggregated] = useState<
    Array<ShidoPriceGraphtype>
  >([]);
  const getPriceAggregated = async () => {
    try {
      const res = await GetShidoPriceGraph();
      setPriceAggregated(res);
    } catch (error) {
      console.error('Error fetching aggregate price:', error);
    }
  };

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    return format(date, 'dd-MM-yyyy HH:mm:ss');
  };

  useEffect(() => {
    getPriceAggregated();
  }, []);

  const config: LineConfig = {
    data: priceAggregated.map((data) => ({
      ...data,
      timestamp: formatTimestamp(data.timestamp),
    })),
    smooth: true,
    xField: 'timestamp',
    yField: 'price',
    xAxis: false,
    yAxis: {
      grid: null,
    },
    color: '#722ED1',
    area: { color: 'rgba(114, 46, 209, 0.3)' },
  };

  return <Line {...config} style={{ height: '226px' }} />;
};

export default ShidoPriceGraph;
