import { Layout, Row, theme } from 'antd';
import { Sidebar } from './components';
import './App.scss';
import { TopHeader } from './components/Header';
import TokenHolders from './pages/TokenHolders';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import { userState } from './redux/reducers/userSlice';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Dashboard from './pages/Dashboard';
import SingleTokenHolder from './pages/SingleTokenHolder';

//const tele = (window as any).Telegram.WebApp;

const { Content } = Layout;
const { useToken } = theme;

const App = () => {
  const { token } = useToken();
  const user = useSelector(userState);

  // useEffect(() => {
  //   tele.ready();
  // }, []);

  useEffect(() => {
    console.log('>>', user);
  }, []);
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        {user.isAuthenticated ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
        {/* {true ? <LoggedInRoutes /> : <NotLoggedInRoutes />} */}
      </Layout>
    </Router>
  );
};

export default App;

const LoggedInRoutes = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout style={{ maxHeight: '100vh' }}>
        <TopHeader />
        <Content style={{ maxHeight: '100%', overflow: 'scroll' }}>
          <Routes>
            <Route path="/Single_Token/:id" element={<SingleTokenHolder />} />
            <Route path={'/Token_Holder'} element={<TokenHolders />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Routes>
        <Route path={'*'} element={<Login />} />
      </Routes>
    </Layout>
  );
};
