import axios from '../axios';

export const GetTokenHoldr = async (): Promise<[]> => {
  try {
    const endPoint = 'token-holders/';
    const res = await axios.get(endPoint);
    console.log(res);

    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error post wallet', err);
    return Promise.reject(err);
  }
};

type UpdateTokenHoldrTagRes = {
  id: number;
  labels: string[];
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  contract_decimals: string;
  contract_name: string;
  contract_ticker_symbol: string;
  contract_address: string;
  supports_erc: null | string;
  logo_url: string;
  address: string;
  balance: string;
  total_supply: string;
  block_height: string;
};

export const UpdateTokenHoldrTag = async (labels: string[], id: number) => {
  console.log('token holder Tag Update function call');

  try {
    const endPoint = `token-holders/${id}/update/`;
    const res = await axios.patch<UpdateTokenHoldrTagRes>(endPoint, { labels });
    if (!res?.data) throw 'Something went wrong';

    return res.data;
  } catch (err) {
    console.log('error update tags', err);
    return Promise.reject(err);
  }
};
