import React from 'react';
import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
// import { colors } from '../Assets/Theme/colors';

const { Text: AntText, Paragraph: AntParagraph } = Typography;

interface textProps extends TextProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  fontSize?: number | string;
  fontWeight?: number | string;
  color?: string;
  // color?: keyof typeof colors | string;
}

export const HeaderText = (props: textProps) => {
  const { fontSize = 22, fontWeight = 600, color = 'white', ...rest } = props;

  return (
    <AntText {...rest} style={{ color, fontWeight, fontSize, ...props.style }}>
      {props.children}
    </AntText>
  );
};

export const Text = (props: textProps) => {
  const {
    fontSize = 14,
    size,
    fontWeight = 400,
    color = 'white',
    ...rest
  } = props;

  const getFontSize = () => {
    if (size) {
      switch (size) {
        case 'xs':
          return 10;
        case 'sm':
          return 12;
        case 'md':
          return 14;
        case 'lg':
          return 16;
        case 'xl':
          return 18;
        case 'xxl':
          return 20;
      }
    }
  };

  return (
    <AntText
      {...rest}
      style={{
        color,
        fontWeight,
        fontSize: getFontSize() ? getFontSize() : fontSize,
        ...props.style,
      }}
    >
      {props.children}
    </AntText>
  );
};

export const Paragraph = (props: textProps) => {
  const {
    fontSize = 14,
    size,
    fontWeight = 400,
    color = 'white',
    ...rest
  } = props;

  const getFontSize = () => {
    if (size) {
      switch (size) {
        case 'xs':
          return 10;
        case 'sm':
          return 12;
        case 'md':
          return 14;
        case 'lg':
          return 16;
        case 'xl':
          return 18;
        case 'xxl':
          return 20;
      }
    }
  };

  return (
    <AntParagraph
      {...rest}
      style={{
        color,
        fontWeight,
        marginBottom: '1.5em',
        fontSize: getFontSize() ? getFontSize() : fontSize,
        ...props.style,
      }}
    >
      {props.children}
    </AntParagraph>
  );
};

export const EllipsisMiddle: React.FC<{
  suffixCount: number;
  children: string;
  textProps?: any;
}> = ({ suffixCount, children, textProps }) => {
  const start = children?.slice(0, children.length - suffixCount).trim();
  const suffix = children?.slice(-suffixCount).trim();
  return (
    <Text
      {...textProps}
      style={{ maxWidth: '90%', ...textProps?.style }}
      ellipsis={{ suffix }}
    >
      {start}
    </Text>
  );
};

export const TruncateString: React.FC<{
  count: number;
  children: string;
  textProps?: any;
}> = ({ count, children, textProps }) => {
  return (
    <Text
      {...textProps}
      style={{ maxWidth: '90%', ...textProps?.style, maxLines: 1 }}
    >
      {`${children.slice(0, count)}${children.length > count ? '...' : ''}`}
    </Text>
  );
};

export const renderParagraph = (text: string) =>
  text.split('\n\n').map((para) => <Paragraph>{para}</Paragraph>);
