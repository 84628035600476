import { Col, Row, Tag, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
const { useToken } = theme;
import { Text } from '../components/Text';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { WalletAgeApi } from '../api';

type walletAgetype = {
  id: number;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  address: string;
  age: number;
  first_txn_time: string;
  Balance: string;
  label: string[];
};

const SingleTokenHolder = () => {
  const { token } = useToken();
  const { md } = useBreakpoint();
  const { id } = useParams();
  const [walletAge, setWalletAge] = useState<walletAgetype>();

  const fetchWalletAge = async () => {
    try {
      if (id) {
        const res = await WalletAgeApi(id);
        setWalletAge(res);
      }
    } catch (err) {
      console.log('Some error While fetching Wallet Age ', err);
    }
  };

  useEffect(() => {
    fetchWalletAge();
  }, [id]);

  const formatDate = (timestamp: string): string =>
    new Date(timestamp).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

  return (
    <>
      <Content
        style={{
          padding: token.paddingLG,
          margin: '16px',
          backgroundColor: token.colorBgContainer,
          borderRadius: '12px',
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ color: 'black', fontWeight: '600', fontSize: '20px' }}>
            {walletAge?.address}
          </Text>
          <Text style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '14px' }}>
            Balance:{' '}
            <span style={{ color: 'black' }}>{walletAge?.Balance}</span>
          </Text>
          <div>
            {walletAge?.label?.map((label, index) => (
              <Tag key={index}>{label}</Tag>
            ))}
          </div>
        </Row>
      </Content>
      <Row
        style={{
          padding: token.paddingLG,
          margin: '16px',
          backgroundColor: token.colorBgContainer,
          borderRadius: '12px',
          // overflow: 'scroll',
          width: md ? '400px' : '',
        }}
      >
        <Text
          style={{
            color: 'black',
            fontWeight: '600',
            fontSize: '20px',
            width: '100%',
          }}
        >
          Age
        </Text>
        <Row
          style={{
            width: '100%',
            padding: '24px 0',
            border: '1px solid rgba(0, 0, 0, 0.06)',
            borderRadius: '8px',
            marginTop: '8px',
          }}
        >
          <Col
            span={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{ color: '#9254DE', fontSize: '24px', fontWeight: '600' }}
            >
              {walletAge?.age}
            </Text>
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Days</Text>
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
            }}
          >
            <Text
              style={{ color: '#9254DE', fontSize: '24px', fontWeight: '600' }}
            >
              {walletAge?.first_txn_time &&
                formatDate(walletAge.first_txn_time)}
            </Text>
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>First tx</Text>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default SingleTokenHolder;
