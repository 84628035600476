import axios from '../axios';
import { StatisticWalletData } from '../../types/DataTypes';

export const GetStatistic = async () => {
  try {
    const endPoint = `token/stats/`;
    const res = await axios.get<StatisticWalletData>(endPoint);
    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error update tags', err);
    return Promise.reject(err);
  }
};
