import axiosClient, { AxiosInstance } from 'axios';
import { store } from '../redux/store';
import { getCookie, clean } from '../utils';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { logout, setToken, userState } from '../redux/reducers';
import { RefreshToken } from './apiCalls';

const TIMEOUT = 35000; //15sec
const SERVER_URL =
  process.env.REACT_APP_API_HOST || 'https://dev.api.covey.academy/api/';

const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  params: clean({
    t: new Date().getTime(),
    sub_id: getCookie('sub_id'),
  }),

  timeout: TIMEOUT,
  // transformResponse: (res) => transformKeys.toCamelCase(JSON.parse(res)),
});

axios.interceptors.request.use(
  async (config) => {
    const token = store.getState()?.user?.token;
    if (token && config?.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['x-api-key'] =
      process.env.REACT_APP_TITLE_X_KEY ||
      '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    console.log('err response in intercept resp >>>>>', error.response.data);
    const originalRequest = error.config;

    if (
      originalRequest.url.includes('refresh-token?') &&
      error.response.status === 401
    ) {
      message.error('Session expired, please login again!');
      store.dispatch(logout());
    } else if (error.response.data.code == 1001 && !originalRequest._retry) {
      const refreshTokenString = store.getState()?.user?.refreshToken;
      if (refreshTokenString) {
        const res = await RefreshToken({ refresh_token: refreshTokenString });
        console.log(
          'Resssssssssssssss>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>tautautau',
          res
        );
        originalRequest._retry = true;
        store.dispatch(setToken(res));

        axios.defaults.headers.common['Authorization'] = `Bearer ${res}`;
        return axios(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
