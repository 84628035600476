import React, { useEffect, useState } from 'react';
import { GetAggregatedGraph } from '../api';
import { Line, LineConfig } from '@ant-design/plots';
import { format } from 'date-fns';

const ShidoAggregatedGraph = () => {
  const transformData = (
    dataArray: number[][]
  ): { timestamp: number; volume: number }[] => {
    return dataArray.map(([timestamp, volume]) => ({
      timestamp,
      volume,
    }));
  };

  const [graphData, setGraphData] = useState<
    { timestamp: number; volume: number }[] | undefined
  >();
  const fetchGraphData = async () => {
    try {
      const res = await GetAggregatedGraph();
      if (res) {
        const transformedData = transformData(res);
        console.log('Transformed data>>', transformedData);
        setGraphData(transformedData);
      }
    } catch (error) {
      console.log('error while Fecth Aggregated Graph data ', error);
    }
  };

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    return format(date, 'dd-MM-yyyy HH:mm:ss');
  };

  useEffect(() => {
    fetchGraphData();
  }, []);

  const config: LineConfig = {
    data: graphData ? graphData : [],
    smooth: true,
    xField: 'volume',
    yField: 'timestamp',
    xAxis: false,
    yAxis: {
      grid: null,
    },
    color: '#722ED1',
    area: { color: 'rgba(114, 46, 209, 0.3)' },
  };

  return <Line {...config} style={{ height: '226px', maxWidth: '100%' }} />;
};

export default ShidoAggregatedGraph;
