import { Avatar, Layout, Row } from 'antd';

import { theme } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import img from '../../src/assets/svg/Logo Placeholder.svg';
import { MenuOutlined } from '@ant-design/icons';
import { closeSidebar, openSidebar } from '../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';

const { Header } = Layout;
const { useToken } = theme;

export const TopHeader = () => {
  const dispatch = useDispatch();
  const mobileSider = useSelector((state: any) => state.app.MobileSider);
  const { token } = useToken();
  const { md } = useBreakpoint();
  return (
    <Header
      style={{
        backgroundColor: token.colorBgContainer,
        display: 'flex',
        alignItems: 'center',
        paddingRight: '16px',
        paddingLeft: '16px',
      }}
    >
      <Row
        style={{
          display: md ? 'none' : 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <img src={img} />

        <Avatar
          shape="square"
          size={32}
          style={{ backgroundColor: token['purple-1'], color: token.purple6 }}
          icon={<MenuOutlined />}
          onClick={() => {
            if (mobileSider) {
              dispatch(closeSidebar());
            } else {
              dispatch(openSidebar());
            }
            console.log('tauseefCheck', mobileSider);
          }}
        />
      </Row>
    </Header>
  );
};

export default TopHeader;
