import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  message,
  theme,
} from 'antd';
import { Text } from '../components/Text';
import { useDispatch } from 'react-redux';
import { login } from '../redux/reducers';
import { Userlogin } from '../api/apiCalls';

const { Content } = Layout;
const { useToken } = theme;

type LoginUser = {
  status: 'success';
  data: {
    user_id: number;
    email: string;
    exp: number;
    access_token: string;
    refresh_token: string;
  };
};

const Login = () => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const error = (errMessage: string) => {
    messageApi.open({
      type: 'error',
      content: errMessage,
    });
  };

  const test = async (values: any) => {
    try {
      const res = await Userlogin({
        email: values.username,
        password: values.password,
      });
      if (res) {
        const payload = {
          user: res.data.email,
          isAuthenticated: true,
          token: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
        dispatch(login(payload));
      }
    } catch (err: any) {
      const errMessage = err.response.data.message;
      error(errMessage);
      console.log(errMessage);
    }
  };

  const onFinish = (values: any) => {
    test(values);
  };
  return (
    <Layout style={{ justifyContent: 'center', alignItems: 'center' }}>
      {contextHolder}
      <Row
        style={{
          gap: '24px',
          padding: token.paddingLG,
          backgroundColor: token.colorBgContainer,
          borderRadius: '8px',
        }}
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text size="xxl" style={{ color: 'black', fontWeight: '600' }}>
            Login
          </Text>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,

                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,

                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input.Password
                //prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Row style={{ justifyContent: 'space-between' }}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Row>
            </Form.Item>

            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
    // <Layout
    //   style={{
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     maxWidth: '100%',
    //     backgroundColor: token.colorBgContainer,
    //   }}
    // >
    //   <Content
    //     style={{
    //       padding: token.paddingLG,
    //       display: 'flex',
    //       flexDirection: 'column',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //       gap: '24px',
    //       maxWidth: '418px',
    //     }}
    //   >
    //     <Row>
    //       <Text size="xxl" style={{ color: 'black', fontWeight: '600' }}>
    //         Login
    //       </Text>
    //     </Row>
    //     <Form
    //       style={{ width: '100%' }}
    //       name="normal_login"
    //       className="login-form"
    //       initialValues={{
    //         remember: true,
    //       }}
    //       onFinish={onFinish}
    //     >
    //       <Form.Item
    //         name="username"
    //         rules={[
    //           {
    //             required: true,

    //             message: 'Please input your Username!',
    //           },
    //         ]}
    //       >
    //         <Input
    //           prefix={<UserOutlined className="site-form-item-icon" />}
    //           placeholder="Username"
    //         />
    //       </Form.Item>

    //       <Form.Item
    //         name="password"
    //         rules={[
    //           {
    //             required: true,

    //             message: 'Please input your Password!',
    //           },
    //         ]}
    //       >
    //         <Input
    //           prefix={<LockOutlined className="site-form-item-icon" />}
    //           type="password"
    //           placeholder="Password"
    //         />
    //       </Form.Item>

    //       <Form.Item>
    //         <Form.Item name="remember" valuePropName="checked" noStyle>
    //           <Checkbox>Remember me</Checkbox>
    //         </Form.Item>

    //         <a className="login-form-forgot" href="">
    //           Forgot password
    //         </a>
    //       </Form.Item>

    //       <Form.Item>
    //         <Button
    //           type="primary"
    //           htmlType="submit"
    //           className="login-form-button"
    //         >
    //           Log in
    //         </Button>
    //         Or <a href="">register now!</a>
    //       </Form.Item>
    //     </Form>
    //   </Content>
    // </Layout>
  );
};

export default Login;
